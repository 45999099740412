import { TZ } from "@chopshop/tz";
import { Select } from "@shopify/polaris";
import { findIndex } from "lodash";
import moment from "moment-timezone";
import React, { useMemo } from "react";

export interface ITZDropDownProp {
  disabled?: boolean;
  label?: string;
  value: TZ;
  onChange: (value: TZ) => void;
}

export function TZDropDown({
  label = "Time zone",
  disabled = false,
  onChange,
  value,
}: ITZDropDownProp): JSX.Element {
  const tznames = useMemo(() => {
    const ts = Date.now();

    return moment.tz
      .names()
      .map(name => {
        const tz = moment.tz.zone(name);
        if (!tz) {
          throw "invalid time zone name " + name;
        }

        const index = findIndex(tz.untils, until => ts < until, tz.untils.length - 1);
        if (index === -1) {
          throw "not found";
        }

        const hh = Math.round(tz.offsets[index] / 60);
        const hhformatted = hh === 0 ? " 0" : hh > 0 ? `-${hh}` : `+${hh * -1}`;
        const isKyivTz = name === "Europe/Kiev";
        return {
          label: `(GMT${hhformatted}) ${isKyivTz ? "Europe/Kyiv" : name}`,
          value: tz?.name ?? "",
          sortedBy: hh,
        };
      })
      .filter(({ label }) => label.includes("/"))
      .sort((a, b) => a.sortedBy - b.sortedBy)
      .map(({ label, value }) => ({ label, value }));
  }, []);

  return <Select disabled={disabled} label={label} options={tznames} onChange={onChange} value={value} />;
}

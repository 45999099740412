import { Icon } from "@shopify/polaris";
import { ClockMajor, SelectMinor } from "@shopify/polaris-icons";
import moment from "moment";
import React from "react";
import styled from "styled-components";

export interface ITimeProps {
  onChange?: any;
  label?: string;
  beginLimit?: string;
  endLimit?: string;
  step?: number;
  defaultValue?: string;
  id?: string;
}
const TimePickerLabel = styled.div`
  & .Polaris-Label__Text {
    color: var(--p-color-text) !important;
  }
`;
const TimePickerWrapper = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
  & .Polaris-Icon {
    & .Polaris-Icon__Svg {
      fill: var(--p-color-icon);
    }
    &:nth-child(1) {
      width: 16px;
    }
  }

  & .Polaris-Select__SelectedOption {
    color: var(--p-color-text);
    margin-left: 1em;
  }
`;

export const TimePicker = function TimePicker({
  onChange,
  label,
  beginLimit,
  endLimit,
  step,
  defaultValue,
  id,
}: ITimeProps) {
  // Format from '13:30:00' -> '1:30 PM'
  defaultValue = moment(defaultValue, ["HH:mm:ss"]).format("h:mm A");

  // Declaring Defaults
  let timeValue = beginLimit || "01:00 AM";
  endLimit = endLimit || "11:30 PM";
  step = step || 30;

  let lastValue;

  function isEarlierThanEndLimit(timeValue: any, endLimit: any, lastValue: any) {
    const timeValueIsEarlier = moment(timeValue, "h:mm A").diff(moment(endLimit, "h:mm A")) < 0;
    const timeValueIsLaterThanLastValue =
      lastValue === undefined ? true : moment(lastValue, "h:mm A").diff(moment(timeValue, "h:mm A")) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  }

  const options: React.ReactNode[] = [];
  options.push(
    <option key={timeValue} value={timeValue}>
      {timeValue}
    </option>
  );

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, "h:mm A").add(step, "minutes").format("h:mm A");
    options.push(
      <option key={timeValue} value={timeValue}>
        {timeValue}
      </option>
    );
  }

  return (
    <div>
      {!!label && (
        <TimePickerLabel className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text">{label}</label>
          </div>
        </TimePickerLabel>
      )}

      <TimePickerWrapper className="Polaris-Select">
        <select
          defaultValue={defaultValue}
          onChange={onChange}
          id={id}
          style={{ color: "var(--p-color-text)" }}
          className={"Polaris-Select__Input"}
        >
          {options}
        </select>
        <div className="Polaris-Select__Content">
          <Icon source={ClockMajor} color="base" />
          <span className="Polaris-Select__SelectedOption">{defaultValue}</span>
          <Icon source={SelectMinor} color="base" />
        </div>

        <div className="Polaris-Select__Backdrop"></div>
      </TimePickerWrapper>
    </div>
  );
};

export default TimePicker;

import { TZ } from "@chopshop/tz";
import { ISOTimeString } from "@smartrr/shared/entities/ISOTimeString";
import { OrgUtils } from "@smartrr/shared/entities/Organization";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import TimePicker from "@vendor-app/app/_sharedComponents/TimePicker";

import { TZDropDown } from "./TZDropDown";

export interface IBillingTimeProps {
  billingTime: ISOTimeString;
  billingTimezone: TZ;
  onChange: (time: ISOTimeString, tz: TZ) => void;
  isAsyncUpdating: boolean;
}

function parseTime(time: string): [number, number] {
  const [HH, mm] = time.split(":").map(hhmm => parseInt(hhmm, 10));
  return [HH, mm];
}

function defaultTZ(billingTimezone: TZ): TZ {
  return billingTimezone && billingTimezone.length > 0 ? billingTimezone : OrgUtils.createDefaultBillingTZ();
}

const ON_CHANGE_DEBOUNCE = 1000;

const BillingTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const BillingTimeDropdownsAndDescContainer = styled.div`
  flex: 3;
  & .Polaris-Label__Text {
    color: var(--p-color-text) !important;
  }
  & .Polaris-Select__SelectedOption {
    color: var(--p-color-text);
  }
`;

const BillingTimeDropdownsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const BillingTimeContainer = styled.div`
  width: 269px;
  & .Polaris-Icon {
    align-content: center;
    display: flex;
    & svg {
      fill: #5c5f62;
    }
  }

  & .Polaris-TextField__Input,
  & .Polaris-TextField {
    max-height: 36px !important;
  }
  @media screen and (max-width: 960px) {
    margin-right: 0;
  }
`;

const TzDropdownContainer = styled.div`
  flex: 1;
  max-width: 269px;

  & .Polaris-Select__Input {
    color: var(--p-color-text) !important;
  }
  @media screen and (max-width: 960px) {
    margin-top: 4px;
  }
`;

export function isInDst(tz: string) {
  return DateTime.now().setZone(tz).isInDST;
}

function toDstHour(hour: number) {
  hour += 1;
  return hour < 24 ? hour : 0;
}

export function toDstTime(t: string): string {
  const [hh, mm, ss] = ISOTimeString.fromString(t as unknown as ISOTimeString);
  return ISOTimeString.toString(toDstHour(hh), mm, ss) as unknown as string;
}

function fromDstHour(hour: number) {
  hour -= 1;
  return hour >= 0 ? hour : 23;
}

// Format from '1:30 PM' -> '13:30:00'
export function formatTime(t: any): string {
  t = moment(t.target.value, ["h:mm A"]).format("HH:mm:ss");
  return t;
}

export function fromDstTime(t: string): string {
  const [hh, mm, ss] = ISOTimeString.fromString(t as unknown as ISOTimeString);
  return ISOTimeString.toString(fromDstHour(hh), mm, ss) as unknown as string;
}

export function BillingTime({
  billingTime,
  billingTimezone,
  onChange,
  isAsyncUpdating,
}: IBillingTimeProps): JSX.Element {
  const [time, setTime] = useState(
    () => (billingTime ?? OrgUtils.createDefaultBillingTime()) as unknown as string
  );

  const [tz, setTz] = useState(defaultTZ(billingTimezone));

  const isDst = useMemo(() => isInDst(tz), [tz]);

  const onChangeCallback = useCallback(
    debounce((timeString: ISOTimeString, newValue: TZ) => onChange(timeString, newValue), ON_CHANGE_DEBOUNCE),
    [onChange]
  );

  const onChangeTz = useCallback(
    (newValue: TZ) => {
      let newTime = time;
      if (isDst !== isInDst(newValue)) {
        newTime = isInDst(newValue) ? toDstTime(time) : fromDstTime(time);
        setTime(newTime);
      }

      setTz(newValue);
      onChangeCallback(ISOTimeString.toString(...parseTime(newTime)), newValue);
    },
    [onChangeCallback, time]
  );

  const onChangeTime = useCallback(
    (newTime: any) => {
      newTime = formatTime(newTime);
      if (isDst) {
        newTime = fromDstTime(newTime);
      }
      setTime(newTime);
      onChangeCallback(ISOTimeString.toString(...parseTime(newTime)), tz);
    },
    [onChangeCallback, tz]
  );

  useEffect(() => {
    setTz(billingTimezone);
    setTime(billingTime);
  }, [billingTimezone, billingTime]);

  return (
    <BillingTimeWrapper>
      <BillingTimeDropdownsAndDescContainer>
        <BillingTimeDropdownsContainer>
          <BillingTimeContainer>
            <TimePicker
              label="Billing time"
              defaultValue={isDst ? toDstTime(time) : time}
              step={30}
              onChange={onChangeTime}
            />
          </BillingTimeContainer>
          <TzDropdownContainer>
            <TZDropDown value={tz} onChange={onChangeTz} disabled={isAsyncUpdating} />
          </TzDropdownContainer>
        </BillingTimeDropdownsContainer>
      </BillingTimeDropdownsAndDescContainer>
    </BillingTimeWrapper>
  );
}

import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminBillingSettingsRoute } from "@vendor-app/app/AdminRoute/AdminBillingSettingsRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/configure/billing-settings")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return AuthorizedRoute(FeatureEnum.CORE_CONFIGURATION, <AdminBillingSettingsRoute />);
}

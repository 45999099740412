import { Page, Spinner } from "@shopify/polaris";
import React from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { BillingEngineSettings } from "./BillingEngineSettings";
import { TabsContainer } from "../components/elements/styles";

export function AdminBillingSettingsRoute(): JSX.Element {
  const isLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoading);
  const isSetupLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoadingSetup);

  if (isLoading || isSetupLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Page narrowWidth>
        <TabsContainer>
          <BillingEngineSettings />
        </TabsContainer>
      </Page>
    </React.Fragment>
  );
}

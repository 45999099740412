import { Select } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface INumericDropDownProps {
  label?: string;
  description?: string;
  options: IOption[];
  value: number;
  helpText?: string;
  onChange: (value: number) => void;
  isAsyncUpdating: boolean;
}

export interface IOption {
  label: string;
  value: number;
}

export function NumericDropDown({
  label,
  options,
  value,
  helpText,
  onChange,
  isAsyncUpdating,
}: INumericDropDownProps): JSX.Element {
  const [currentValue, setValue] = useState<number>(value);

  const onChangeListener = useCallback(
    // dealing with the fact that polaris dropdown only allows strings
    (numericStringValue: string) => {
      const newValue = +numericStringValue;
      setValue(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Select
      label={label}
      disabled={isAsyncUpdating}
      options={useMemo(() => options.map(({ label, value }) => ({ label, value: "" + value })), [options])}
      onChange={onChangeListener}
      value={"" + currentValue}
      helpText={helpText}
    />
  );
}
